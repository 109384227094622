export default class StorageService {

    static set(key, value) {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }
        sessionStorage.setItem(key, value);
    }

    static get(key) {
        let dataString = sessionStorage.getItem(key);
        if (!dataString) {
            return null;
        }
        try {
            return JSON.parse(dataString);
        } catch (e) {
            // Not json object
            return dataString
        }
    }

    static remove(key) {
        sessionStorage.removeItem(key);
    }

    static clear() {
        sessionStorage.clear();
    }

    static setLocal(key, value) {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    }

    static getLocal(key) {
        let dataString = localStorage.getItem(key);
        if (!dataString) {
            return null;
        }
        try {
            return JSON.parse(dataString);
        } catch (e) {
            // Not json object
            return dataString
        }
    }

    static removeLocal(key) {
        localStorage.removeItem(key);
    }

    static clearLocal() {
        localStorage.clear();
    }
}
