export const isBrowser = () => typeof window !== "undefined";

export const  getCookie = (name) => {
    const cookieArr = document.cookie.split(";");
    for(let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if(`pt_${name}` === cookiePair[0].trim() || name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

export const setStreamData = (data) => {
    if (isBrowser()) {
        window.streamData = window.streamData || {};
        window.streamData = Object.assign(window.streamData, data);
        window.streamData.url = window.location.href
        console.log(window.streamData);
    }
}

export const sendEventToDataStream = () => {
    const clientId = getCookie('_ga');
    fetch('https://api.agegroup.io/stream/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: clientId, data: window.streamData})
    })
}
