const CONST = {
  CATEGORY_CHUNK_SIZE: 5,
  AD_REFRESH_TIME: 120000,
  SCRIPT_LOADING_TIME_WAIT: 1200,
  USER_STORAGE_KEY: 'ag_user',
  PAGE_VISITED: 'ag_page_visited',
  WEBSITE_ABBREVIATION: 'AT',
  COUNTRY_WISE_WEBSITE: {
    GB: {
      domain: 'co.uk',
      host: `https://${process.env.GATSBY_DEPLOYMENT_ENV ? process.env.GATSBY_DEPLOYMENT_ENV : 'www'}.agetimes.co.uk`,
      mappedDomainField: 'coUkArticle'
    },
    US: {
      domain: '.com',
      host: `https://${process.env.GATSBY_DEPLOYMENT_ENV ? process.env.GATSBY_DEPLOYMENT_ENV : 'www'}.agetimes.com`,
      mappedDomainField: 'dotComArticle'
    }
  },
  WEBSITE_MAPPING: {
    'pt': `https://${process.env.GATSBY_DEPLOYMENT_ENV ? process.env.GATSBY_DEPLOYMENT_ENV : 'www'}.pensiontimes.co.uk`,
    'ht': `https://${process.env.GATSBY_DEPLOYMENT_ENV ? process.env.GATSBY_DEPLOYMENT_ENV : 'www'}.healthtimes.co.uk`
  }
}

Object.freeze(CONST)

export default CONST
